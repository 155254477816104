import { useEffect, useRef, useState } from 'react'
import Button from '../UI/Button'
import Modal from '../UI/Modal'
import IconChevronLeft from '../UI/Icons/IconChevronLeft'
import IconChevronRight from '../UI/Icons/IconChevronRight'
import IconClose from '../UI/Icons/IconClose'
import styles from './Controls.module.css'
import TitleLogo from '../Phase2/components/TitleLogo'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import SingleTitle from '../Phase2/components/SingleTitle'
import TitleChasin from './TitleChasin'
import IconSpotify from '../UI/Icons/IconSpotify'
import IconAppleMusic from '../UI/Icons/IconAppleMusic'
import IconAmazonMusic from '../UI/Icons/IconAmazonMusic'
import EmailForm from '../Intro/EmailForm'

export default function Controls(props) {
  const [ctaActive, setCtaActive] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [single1Status, setSingle1Status] = useState('ready');
  const [single2Status, setSingle2Status] = useState('ready');

  const [preorderModalActive, setPreorderModalActive] = useState(false);

  const [emailFormActive, setEmailFormActive] = useState();
  const [currentPatch, setCurrentPatch] = useState();
  const [single1ModalActive, setSingle1ModalActive] = useState(false);
  const [single2ModalActive, setSingle2ModalActive] = useState(false);
    
  const [serviceId, setServiceId] = useState();
  const [serviceName, setServiceName] = useState();

  const [phoneStatus, setPhoneStatus] = useState('ready');
  const phoneRef = useRef();

  const [error, setError] = useState();

  useEffect(() => {
    setCtaActive(false);

    setTimeout(() => {
      setCtaActive(true)
    }, 2000);
  }, [props.platform]) 

  const navigate = (e) => {
    setCtaActive(false);

    setTimeout(() => {
      const direction = e.target.dataset.direction;

      const platform_count = 5;
      
      if (direction === 'next' && props.platform < platform_count) {
        props.handleNavigate(props.platform + 1);
      } else if (direction === 'next') {
        props.handleNavigate(1);
      } else if (direction === 'prev' && props.platform > 1) {
        props.handleNavigate(props.platform - 1);
      } else if (direction === 'prev') {
        props.handleNavigate(platform_count);
      }
    }, 500);
  }

  const changeProduct = (e) => {
    if (props.merchMoving) {
      return;
    }

    let direction = e.target.dataset.direction;
    const product_count = 8;
    let new_product;
    if (direction === 'next' && props.currentProduct.id < product_count) {
      new_product = props.currentProduct.id + 1;
    } else if (direction === 'next') {
      new_product = 1;
    } else if (direction === 'prev' && props.currentProduct.id > 1) {
      new_product = props.currentProduct.id - 1;
    } else if (direction === 'prev') {
      new_product = product_count;
    }

    props.updateProduct(new_product)
  }

  const handlePhoneSubmit = async (e) => {
    try {
      e.preventDefault();

      if (!phoneNumber) {
        return setError('Please add your phone number')
      }
      setError(null)
      setPhoneStatus('loading')

      const response = await fetch('/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({phone: phoneNumber})
      })

      if (!response.ok) {
        setPhoneStatus('added')
      }
      
      setPhoneStatus('added')
    } catch (error) {
      console.log(error)
    }
  }

  const openSaveModal1 = (e) => {
    const patch_id = e.target.dataset.id;
    setCurrentPatch(patch_id)
    setSingle1ModalActive(true);
  }
  const closeSaveModal1 = (e) => {
    setSingle1ModalActive(false);
  }

  const openSaveModal2 = (e) => {
    const patch_id = e.target.dataset.id;
    setCurrentPatch(patch_id)
    setSingle2ModalActive(true);
  }
  const closeSaveModal2 = (e) => {
    setSingle2ModalActive(false);
  }


  const openEmailForm = (e) => {
    closeSaveModal1(false);
    closeSaveModal2(false);
    setServiceId(e.target.dataset.id)
    setServiceName(e.target.dataset.role)
    setEmailFormActive(true);
  }

  const closeEmailForm = () => {
    setEmailFormActive(false);
    setServiceId(null)
    setServiceName(null)
    setCurrentPatch(null)
  }

  const saveMedia = (e) => {
    try {
      if (window.sonikit) {
        if (props.fan && props.fan.service === serviceName) {
          window.sonikit.triggerAction(currentPatch);
          closeEmailForm();
        } else {
          window.sonikit.authenticate(currentPatch, serviceId);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const openPreorderModal = () => {
    setPreorderModalActive(true);
  }
  const closePreorderModal = () => {
    setPreorderModalActive(false);
  }

  return (
    <>
      {props.platform === 1 && (
        <>
          <div className={styles.titleLogo} data-active={ctaActive}>
            <TitleLogo />
          </div>
          <div className={styles.wrapper} data-active={ctaActive}>
            <Button handleClick={openPreorderModal} size='block'>ORDER</Button>
            <a href='https://talkshop.live/watch/er0G9xbVhXI0' target='_blank'><Button size='block'>ORDER (SIGNED)</Button></a>
          </div>

          <Modal
            active={preorderModalActive}
            close={closePreorderModal}
          >
            <div className={styles.saveContent}>
              <h3>Choose a store</h3>
              <a href='https://shop.parishilton.com/products/cd' target='_blank'><Button>PARIS HILTON</Button></a>
              <a href='https://www.amazon.com/dp/B0D9MD6D9D' target='_blank'><Button>AMAZON</Button></a>
              <a href='https://www.target.com/p/paris-hilton-infinite-icon-cd/-/A-92969245' target='_blank'><Button>TARGET</Button></a>
              <a href='https://www.walmart.com/ip/seort/8538853799' target='_blank'><Button>WALMART</Button></a>
            </div>
          </Modal>
        </>
      )}

      {props.platform === 4 && (
        <>
          <div className={styles.titleLogo} data-active={ctaActive}>
            <SingleTitle />
          </div>
          <div className={styles.wrapper} data-active={ctaActive}>
            <Button handleClick={openSaveModal1} id='66a38c8d2a45341609dd0683' state={single1Status} size='block'>
              {single1Status === 'ready' && 'ADD TO LIBRARY'}
              {single1Status === 'loading' && 'ADDING TRACK'}
              {single1Status === 'saved' && 'TRACK ADDED'}
            </Button>
          </div>

          <Modal
            active={single1ModalActive}
            close={closeSaveModal1}
          >
            <div className={styles.saveContent}>
              <h3>Choose a streaming service</h3>
              <Button handleClick={openEmailForm} id='66a38c8d2a45341609dd0686' role='spotify'><IconSpotify /> SPOTIFY</Button>
              <Button handleClick={openEmailForm} id='66a39bac2a45341609dd32e5' role='apple-music'><IconAppleMusic /> APPLE MUSIC</Button>
              <Button handleClick={openEmailForm} id='66a39bd02a45341609dd3443' role='amazon-music'><IconAmazonMusic />AMAZON MUSIC</Button>
            </div>
          </Modal>
        </>
      )}

      {props.platform === 5 && (
        <>
          {/* <div className={styles.titleLogo} data-active={ctaActive}>
            <SingleTitle />
          </div> */}
          <div className={styles.wrapper} data-active={ctaActive}>
            <Button handleClick={openSaveModal1} id='66a38c8d2a45341609dd0683' state={single1Status} size='block'>
              {single1Status === 'ready' && 'ADD TO LIBRARY'}
              {single1Status === 'loading' && 'ADDING TRACK'}
              {single1Status === 'saved' && 'TRACK ADDED'}
            </Button>
          </div>

          <Modal
            active={single1ModalActive}
            close={closeSaveModal1}
          >
            <div className={styles.saveContent}>
              <h3>Choose a streaming service</h3>
              <Button handleClick={openEmailForm} id='66a38c8d2a45341609dd0686' role='spotify'><IconSpotify /> SPOTIFY</Button>
              <Button handleClick={openEmailForm} id='66a39bac2a45341609dd32e5' role='apple-music'><IconAppleMusic /> APPLE MUSIC</Button>
              <Button handleClick={openEmailForm} id='66a39bd02a45341609dd3443' role='amazon-music'><IconAmazonMusic />AMAZON MUSIC</Button>
            </div>
          </Modal>
        </>
      )}

      {props.platform === 3 && (
        <>

          
          {props.xp !== 'phone' && (
            <>
              <div className={styles.titleLogo} data-active={ctaActive}>
                <TitleChasin />
              </div>
              <div className={styles.wrapper} data-active={ctaActive}>
                <Button handleClick={openSaveModal2} id='66a38ca02a45341609dd06d1' state={single2Status} size='block'>
                  {single2Status === 'ready' && 'ADD TO LIBRARY'}
                  {single2Status === 'loading' && 'ADDING TRACK'}
                  {single2Status === 'saved' && 'TRACK ADDED'}
                </Button>
              </div>
              <Modal
                active={single2ModalActive}
                close={closeSaveModal2}
              >
                <div className={styles.saveContent}>
                  <h3>Choose a streaming service</h3>
                  <Button handleClick={openEmailForm} id='66a38ca02a45341609dd06d4' role='spotify'><IconSpotify /> SPOTIFY</Button>
                  <Button handleClick={openEmailForm} id='66a39bc02a45341609dd33ca' role='apple-music'><IconAppleMusic /> APPLE MUSIC</Button>
                  <Button handleClick={openEmailForm} id='66a39bda2a45341609dd34d0' role='amazon-music'><IconAmazonMusic />AMAZON MUSIC</Button>
                </div>
              </Modal>
            </>
          )}
          {props.xp === 'phone' && (
            <div className={styles.wrapper} data-active={ctaActive} data-role='form'>
              <div className={styles.close}><Button handleClick={props.closeXp} layout='text'>CLOSE <IconClose /></Button></div>
              

              {(phoneStatus === 'ready' || phoneStatus === 'loading') && (
                <>
                  <div className={styles.videoTitle}>TEXT PARIS</div>
                  <p>
                    Enter your phone number to receive text updates:
                  </p>
                  <form className='form-block' onSubmit={handlePhoneSubmit} >
                    <div className={styles.inputWrap}>
                      <PhoneInput
                        placeholder="Phone number"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        defaultCountry="US"
                      />
                    </div>
                    {error}
                    <Button state={phoneStatus} size='block'>SUBMIT</Button>
                  </form>
                </>
                  
              )}
              {phoneStatus === 'added' && (
                <div>
                  Phone number added!
                  <Button handleClick={props.closeXp} size='block'>CLOSE</Button>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {props.platform === 2 && props.currentProduct && (
        <>
          <div className={styles.merchTitle} data-active={ctaActive && !props.merchMoving}>
            <div className={styles.merchSectionHeader}>Infinite Icon Collection</div>
            <div className={styles.productName}>{!props.merchMoving && props.currentProduct.name}</div>
          </div>
          
          <div className={styles.wrapper} data-active={ctaActive && !props.merchMoving}>
            <button onClick={changeProduct} className={styles.merchNav} data-direction='prev'><IconChevronLeft /></button>
            <button onClick={changeProduct} className={styles.merchNav} data-direction='next'><IconChevronRight /></button>
            <a href={props.currentProduct.url} target='_blank'><Button size='block'>SHOP NOW</Button></a>
          </div>
        </>
      )}
      
      {emailFormActive && <EmailForm close={closeEmailForm} fan={props.fan} authenticate={saveMedia} />}

      <button onClick={navigate} className={styles.navBtn} data-direction='prev'><IconChevronLeft /></button>
      <button onClick={navigate} className={styles.navBtn} data-direction='next'><IconChevronRight /></button>
    </>
  )
}