import { useEffect, useState, useRef } from 'react'
import styles from './EmailForm.module.css'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Spinner from '../UI/Spinner'

export default function EmailForm(props) {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const mode = 'before-presave'

  const [phoneNumber, setPhoneNumber] = useState()

  const emailRef = useRef()
  const consentRef = useRef()

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 100);
  }, [])

  const handleClose = () => {
    setActive(false)
    
    setTimeout(() => {
      props.close()
    }, 1000);
  }

  const handleSkip = () => {
    props.authenticate()
    // setLoading(true)

    // handleClose();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(null)
    setLoading(true)
    try {
      if (window.sonikit) {
        let body = {};

        if (emailRef.current.value) {
          body.email = emailRef.current.value;
        }

        if (phoneNumber && phoneNumber !== '') {
          body.phone = phoneNumber
        }

        if (!body.email && !body.phone) {
          setLoading(false)
          return setError('Please include an email or phone number')
        }

        if (!consentRef.current.checked) {
          setLoading(false)
          return setError('Please agree to the terms below by clicking the checkbox.')
        }

        if (mode === 'before-presave') {
          const response = await fetch(`https://api.sonikit.com/js/temp-contact`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${window.sonikit.project.id}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
      
          if (!response.ok) {
            // props.authenticate();
            setLoading(false)
            return console.log(response.status)
          }
      
          const response_data = await response.json();

          window.sonikit.storage.contact_id = response_data.id;
          window.sonikit.storage.contact_token = response_data.token;
          localStorage.setItem('sonikit-store', JSON.stringify(window.sonikit.storage));


          
        } else {
          const sonikit_response = await fetch(`https://api.sonikit.com/fans/${props.fan._id}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer 663d61c6c6b6ba600bef4cb2`, // sonikit project id
              'Content-Type': 'application/json'
            },
            body: JSON.stringify( body )
          })

          if (!sonikit_response.ok) {
            // props.authenticate();
            setLoading(false)
            return console.log(sonikit_response.status)
          }
          // console.log(sonikit_response.status)
          handleClose();
          return 
        }
      }

      props.authenticate();
    } catch (error) {
      console.log('error: ', error);
      setLoading(false)
      props.authenticate();
    }
  }

  return (
    <div className={styles.wrapper} data-active={active}>
        <div className={styles.content}>
          
          {/* <div onClick={handleClose} className={styles.close}>
            <IconClose />
          </div> */}

          <form onSubmit={handleSubmit} className={styles.inputGroup}>
            {!loading && (
              <>
                <div className={styles.title}>
                  Add your email address and phone number to get updates from Paris:
                </div>

                
                <div className={styles.inputWrap}>
                  <input ref={emailRef} type='email' placeholder='Email address' onChange={e => setError(null)} />
                </div>
                <div className={styles.inputWrap}>
                  <PhoneInput
                    placeholder="Phone number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    defaultCountry="US"
                  />
                </div>
                <div className={styles.error}>{error}</div>
                <button size='block'>SUBMIT</button>

                

                <div className={styles.disclaimer}>
                  <label>
                    <input ref={consentRef} type='checkbox' name='consent' />
                  </label>
                  <div className={styles.disclaimerText}>
                    I agree to receive emails from Paris Hilton. See <a href='https://parishilton.com/privacy-policy/' target='_blank'>Privacy Policy</a>. You may unsubscribe at any time.
                    By submitting this form, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) from Paris Hilton at the cell number used when signing up. 
                    Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply. View Terms & Privacy.
                  </div>
                </div>
              </>
            )}
            {loading && (
              <div className={styles.loader}>
                <Spinner color='black' size='large' />
                Processing...
              </div>
            )}
          </form>

          {!loading && <button onClick={handleSkip} type='button' className={styles.skip}>SKIP {">>"}</button>}
        </div>

      
    </div>
  )
}